import styled from "styled-components"

interface TextProps {
  size?: string
}

export const Text = styled.div<TextProps>`
  font-size: ${props => (props.size ? props.size : "1rem")};
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
`
