import { configureStore } from "@reduxjs/toolkit"

import authReducer from "./authSlice"
import rightsRequestReducer from "./rightsRequestSlice"
import confirmationReducer from "./confirmationSlice"

// Sync resux between tabs, used during instagram auth connection
// import {
//   createStateSyncMiddleware,
//   initMessageListener,
// } from "redux-state-sync"
// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     rightsRequest: rightsRequestReducer,
//   },
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware().concat(createStateSyncMiddleware({})),
// })

// initMessageListener(store)

export const store = configureStore({
  reducer: {
    auth: authReducer,
    rightsRequest: rightsRequestReducer,
    confirmation: confirmationReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
