import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Loader from "../../components/commons/loader"
import { getInstagramAccessToken } from "../../services/RightRequestService"
import { useAppDispatch } from "../../redux/hooks"
import { setInstagramAuth } from "../../redux/authSlice"
import { LoadingScreen } from "../../components/commons/loader/Loader.styles"

const InstagramAuthPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (searchParams.has("error")) {
      console.log(searchParams.has("error_reason"))
      console.log(searchParams.has("error_description"))
      dispatch(
        setInstagramAuth({
          error:
            "We cannot show the content due to missing permissions. Make sure you log in with the right Instagram account and give permission to display your content.",
        })
      )
      window.close()
    } else if (searchParams.has("code")) {
      const code = searchParams.get("code")
      if (code) {
        console.log(code)
        getInstagramAccessToken(code).then(res => {
          console.log(res)
          dispatch(
            setInstagramAuth(
              res as {
                access_token: string
                userId: string
              }
            )
          )
          window.close()
        })
      }
    } else {
      dispatch(
        setInstagramAuth({
          error:
            "We cannot show the content due to missing permissions. Make sure you log in with the right Instagram account and give permission to display your content.",
        })
      )
      window.close()
    }
  }, [searchParams])

  return (
    <LoadingScreen>
      <Loader />
      <p className="pt-3">Please wait while loading...</p>
    </LoadingScreen>
  )
}

export default InstagramAuthPage
