import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"
import GlobalStyles from "./styles/global"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { store } from "./redux/store"
import { Provider } from "react-redux"

import RightsRequestPage from "./pages/RightsRequestPage"
import FormPage from "./pages/PublicPages/FormPage"
import InstagramAuthPage from "./pages/InstagramAuthPage"
import NotFound from "./pages/NotFound"
import ConfirmationPage from "./pages/ConfirmationPage/ConfirmationPage"

const router = createBrowserRouter([
  {
    path: "/",
    element: <NotFound />,
    errorElement: <NotFound />,
  },
  {
    path: "/form/v2/:campaignId",
    element: <FormPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/:rightRequestShortId",
    element: <RightsRequestPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/auth",
    element: <InstagramAuthPage />,
  },
  {
    path: "/confirmation/:confirmationId",
    element: <ConfirmationPage />,
  },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyles />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)
