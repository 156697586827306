import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import { Wrapper } from "../../styles/global"

import * as S from "./ConfirmationPage.styles"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import Footer from "../../components/commons/Footer"
import { fetchConfirmation } from "../../services/ConfirmationService"
import ConfirmationPageTemplate from "./components/ConfirmationPageTemplate"
import {
  selectIsLoadingConfirmation,
  setConfirmation,
  setIsLoadingConfirmation,
} from "../../redux/confirmationSlice"
import { Confirmation } from "../../types/Confirmation"
import ConfirmationAssets from "./components/ConfirmationAssets"
import ConfirmationValues from "./components/ConfirmationValues"

import {
  SkeletonImage,
  SkeletonContainer,
  SkeletonText,
} from "../../components/commons/Skeleton"

const ConfirmationPage: React.FC = () => {
  const { confirmationId } = useParams()
  const isLoading = useAppSelector(selectIsLoadingConfirmation)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (confirmationId) {
      fetchConfirmation(confirmationId)
        .then(res => {
          dispatch(setConfirmation({ ...res } as Confirmation))
          dispatch(setIsLoadingConfirmation(false))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [confirmationId])

  if (isLoading) {
    return (
      <S.Styles>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <Wrapper maxWidth="100%">
                <div className="d-flex align-self-center p-4">
                  <SkeletonImage width="200px" height="150px" />
                </div>
                <SkeletonContainer>
                  <div className="mb-3">
                    <SkeletonText width="75%" />
                  </div>
                  <SkeletonText width="100%" />
                  <SkeletonText width="100%" />
                  <SkeletonText width="100%" />
                  <SkeletonText width="100%" />
                </SkeletonContainer>
                <div className="mt-3 w-100">
                  <S.ContentContainer>
                    <SkeletonImage width="100%" height="200px" />

                    <SkeletonImage width="100%" height="200px" />

                    <SkeletonImage width="100%" height="200px" />

                    <SkeletonImage width="100%" height="200px" />

                    <SkeletonImage width="100%" height="200px" />

                    <SkeletonImage width="100%" height="200px" />
                  </S.ContentContainer>
                </div>
              </Wrapper>
            </Col>
          </Row>
        </Container>
      </S.Styles>
    )
  }

  return (
    <S.Styles>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <ConfirmationPageTemplate />
          </Col>
        </Row>
        <ConfirmationValues />
        <Row className="justify-content-md-center">
          <Col md={8}>
            <hr className="solid" />
            <h2>Uploaded media</h2>

            <ConfirmationAssets />
          </Col>
        </Row>

        <Footer />
      </Container>
    </S.Styles>
  )
}

export default ConfirmationPage
