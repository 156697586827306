import axios from "axios"
import { Form } from "../types/Form"

export const fetchForm = async (
  campaignId: string
): Promise<Form | undefined> => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_FIREBASE_API_URL + "/form/" + campaignId
    )
    return data as Form
  } catch (err) {
    console.error(err)
    return undefined
  }
}
