import axios from "axios"
import { Confirmation } from "../types/Confirmation"

export const fetchConfirmation = async (
  confirmationId: string
): Promise<Confirmation | undefined> => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_FIREBASE_API_URL + "/confirmation/" + confirmationId
    )
    return data.confirmation as Confirmation
  } catch (err) {
    console.error(err)
    return undefined
  }
}
