import styled from "styled-components"
import { colors } from "../../../../styles/variables"

interface Props {
  isChecked: boolean
}
export const ContentContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
`

export const AssetContainer = styled.div<Props>`
  position: relative !important;

  cursor: pointer;
  border: ${({ isChecked }) =>
    isChecked
      ? `2px solid ${colors.reeler} !important`
      : `2px solid ${colors.reelerDanger} !important`};
  background: #f8f9fa !important;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
`

export const MediaContainer = styled.div`
  height: 200px;
  cursor: pointer;
  text-align: center !important;
`

export const TopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

export const Link = styled.button`
  outline: none;
  border: none;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }
`
