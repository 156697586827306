import * as S from "./skeleton-styles"

interface Props {
  width: string
  height: string
}

const SkeletonImage: React.FC<Props> = ({ width, height }) => {
  return <S.SkeletonImage width={width} height={height} />
}

export default SkeletonImage
