import React from "react"
import * as S from "./Alert.styles"

interface Props {
  className?: string
  variant?: string
  children: React.ReactNode
}

// import logo from "../assets/logo.png"
const Alert: React.FC<Props> = ({ variant, className, children }) => {
  return (
    <S.Container variant={variant} className={className}>
      {children}
    </S.Container>
  )
}

export default Alert
