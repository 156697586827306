import React from "react"
import * as S from "./ApproveRejectButton.styles"
import Loader from "../loader"

interface Props extends React.HTMLProps<HTMLButtonElement> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void // to handle onClick functions
  color: string
  disabled: boolean
  loading?: boolean
  children: React.ReactNode
}

// import logo from "../assets/logo.png"
const Button: React.FC<Props> = ({
  onClick,
  color,
  disabled,
  loading,
  children,
}) => {
  return (
    <S.Button color={color} onClick={onClick} disabled={disabled}>
      {loading ? (
        <span className="d-flex flex-row">
          <Loader />
          <span style={{ marginLeft: "0.5rem" }}>Please wait...</span>{" "}
        </span>
      ) : (
        children
      )}
    </S.Button>
  )
}

export default Button
