export const STATUS = [
  { label: "Approved by uploader", status: "approved" },
  { label: "Approved by third party", status: "approved-third-party-approve" },
  { label: "Pending by uploader", status: "pending" },
  { label: "Pending by third party", status: "pending-third-party-approve" },
  { label: "Not approved by uploader", status: "not-approved" },
  { label: "Not approved by third party", status: "not-approved-third-party" },
]

export const ASSET_STATUS = {
  created: "created",
  approved: "approved",
  approvedThirdPartyApprove: "approved-third-party-approve",
  pending: "pending",
  pendingThirdPartyApprove: "pending-third-party-approve",
  notApproved: "not-approved",
  notApprovedThirdParty: "not-approved-third-party",
}

export const ASSET_RIGHT_STATUS = {
  created: "created",
  pending: "pending",
  approved: "approved",
  notApproved: "not-approved",
}

export const CAMPAIGN_STATUS = {
  active: "active",
  inactive: "inactive",
  deleting: "deleting",
}

export const ASSET_STATE = {
  unreviewed: "unreviewed",
  pending: "pending",
  approved: "approved",
  rejected: "rejected",
  deleted: "deleted",
}

export const CAMPAIGN_TYPE = {
  upload: "upload_2",
  igHashtag: "hashtag",
  igMentions: "mentions",
  email: "email",
}

export const MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  TEXT: "TEXT",
}

export const IMG_SIZES = {
  thumb: 200,
  preView: 500,
}

export const DOWNLOAD_FILE_STATES = {
  created: "created",
  processing: "processing",
  finished: "finished",
  error: "error",
}
export const PUBLISHING_JOB_TYPES = {
  instagram: "instagram",
  facebook: "facebook",
  youtube: "youtube",
}

export const PUBLISHING_JOB_STATUS = {
  inProgress: "IN_PROGRESS",
  published: "PUBLISHED",
  expired: "EXPIRED",
  error: "ERROR",
}

export const RIGHT_REQUEST_STATUS = {
  created: "CREATED",
  error: "ERROR",
  completed: "COMPLETED",
}

export const INSTAGRAM_MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  ALBUM: "ALBUM",
}

export const VERIFICATION_METHOD = {
  none: "NONE",
  instagram: "INSTAGRAM",
}

export const RIGHTS_REQUEST_APPROVAL_STATUS = {
  created: "created",
  pending: "pending",
  approved: "approved",
  notApproved: "not-approved",
}
