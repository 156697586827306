export const colors = {
  reeler: "#1ee9be",
  reelerLight: "#77f2d8",
  main: "#ffffff",
  black: "#3c3c3c",
  black100: "#5d5d5d",
  white: "#ffffff",
  reelerSuperLight: "#d1faf2",
  reelerMidGreen: "#77f2d8",
  reelerLightGrey: "#f8f8f8",
  reelerGrey: "#f2f2f5",
  reelerGreyMiddle: "#e2e2e9",
  reelerGreyDark: "#d9d9d9",
  reelerGrayDarker: "#b3b3b3",
  reelerContainer: "#f2f2f5",
  reelerContainerYellow: "#f3f2ef",
  reelerLink: "#707070",
  reelerInput: "#ced4da",
  reelerIcon: "#707070",
  reelerHover: "#3c404314",
  reelerTitle: "#4c4c4c",
  reelerBadge: "#4c4c4c",
  reelerBlack: "#3c3c3c",
  reelerDarkBlue: "#1f2e46",
  reelerDanger: "#f97676",
  reelerWarning: "#ffc107",
  reelerEdit: "#007bff",
  reelerEditHover: "#0069d9",
  colorAttentionSuble: "#fff8c5",
  colorAttentionMuted: "rgba(212, 167, 44, 0.4)",
  reelerDangerLight: "#fdbfbf",
  reelerDangerHover: "#c01717",
  reelerShadow: `rgba(112, 112, 112, 0.13) 0px 3.2px 7.2px 0px,
  rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px`,
  reelerShadowInset: "rgba(112, 112, 112, 1) 3.2px inset",
  reelerO25: "rgba(32, 233, 189, 0.25)",
  reelerLightO25: "rgba(119, 242, 215, 0.25)",
  bootstrapBorder: "1px solid #dee2e6",
}

export const fontSize = {
  p: "12px",
  h1: "32px",
  h2: "24px",
  h3: "16px",
}

export const fontWeights = {
  thin: "300",
  normal: "500",
  bold600: "600",
  bold800: "800",
}

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
}
