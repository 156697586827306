import React from "react"
import moment from "moment"

// Third party

import { Row, Col } from "react-bootstrap"
import * as S from "../ConfirmationPage.styles"

import { selectConfirmation } from "../../../redux/confirmationSlice"
import { useAppSelector } from "../../../redux/hooks"

import Media from "../../../components/commons/Media"
//import DownloadAssets from "./components/DownloadAssets"

moment.locale()

const ConfirmationAssets: React.FC = () => {
  const confirmation = useAppSelector(selectConfirmation)

  return (
    confirmation && (
      <>
        <Row className="mb-4">
          <Col>
            <S.ContentContainer>
              {confirmation?.mediaAssets?.map(mediaAsset => (
                <S.MediaContainer>
                  <Media
                    url={
                      process.env.REACT_APP_FIREBASE_API_URL +
                      "/v1.0/media/" +
                      mediaAsset.id +
                      "?size=200&cache_age=3600"
                    }
                    mediaType={mediaAsset.media_type}
                  />
                </S.MediaContainer>
              ))}
            </S.ContentContainer>
          </Col>
        </Row>
      </>
    )
  )
}

export default ConfirmationAssets
