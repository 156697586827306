import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "./store"

import { Confirmation } from "../types/Confirmation"

// Define a type for the slice state
interface ConfirmationState {
  confirmation: Confirmation | null
  isLoadingConfirmation: boolean
}

// Define the initial state using that type
const initialState: ConfirmationState = {
  confirmation: null,
  isLoadingConfirmation: true,
}

export const confirmationSlice = createSlice({
  name: "confirmation",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setConfirmation: (state, action: PayloadAction<Confirmation>) => {
      state.confirmation = action.payload
    },
    setIsLoadingConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isLoadingConfirmation = action.payload
    },
    clearConfirmation: state => {
      state.confirmation = null
    },
  },
})

export const { setConfirmation, setIsLoadingConfirmation, clearConfirmation } =
  confirmationSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectConfirmation = (state: RootState) =>
  state.confirmation.confirmation
export const selectIsLoadingConfirmation = (state: RootState) =>
  state.confirmation.isLoadingConfirmation

export default confirmationSlice.reducer
