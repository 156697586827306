import React from "react"
import { useAppSelector } from "../../../redux/hooks"

import { selectRightsRequest } from "../../../redux/rightsRequestSlice"
import { Wrapper } from "../../../styles/global"

const TermsAndConditions: React.FC = () => {
  const rightsRequest = useAppSelector(selectRightsRequest)
  //const instagramMedia = useAppSelector(selectInstagramMedia)
  return (
    <Wrapper>
      {rightsRequest?.approvalPage?.terms ? (
        <small>
          <a href={rightsRequest?.approvalPage?.terms} target="_blank">
            Terms & Conditions
          </a>
        </small>
      ) : null}
      {rightsRequest?.approvalPage?.privacyPolicy ? (
        <small>
          <a href={rightsRequest?.approvalPage?.privacyPolicy} target="_blank">
            Privacy policy
          </a>
        </small>
      ) : null}
    </Wrapper>
  )
}

export default TermsAndConditions
