import styled, { keyframes } from "styled-components"

const skeletonLoading = keyframes`
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
`

interface TextProps {
  width?: string
}

export const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const SkeletonImage = styled.img`
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "100%")};

  animation: ${skeletonLoading} 1s linear infinite alternate;
  border-radius: 4px;
`

export const SkeletonText = styled.div<TextProps>`
  width: ${props => (props.width ? props.width : "100%")};
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  animation: ${skeletonLoading} 1s linear infinite alternate;
`
