import styled, { createGlobalStyle } from "styled-components"
import { PageText } from "../helpers/PageText"
import { PageItemWrapper as ItemWrapper } from "../helpers/ItemWrapper"
import { colors, devices, fontWeights } from "./variables"

const GlobalStyles = createGlobalStyle`
html {
    background-color: ${colors.main};
}
*{
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing:border-box;
    font-family: 'Open Sans', sans-serif; 
}
#root{
    margin:0 auto;
}
`

export const Container = styled.div`
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.white};
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media ${devices.mobileM} {
    max-width: 600px;
  }

  @media ${devices.desktop} {
    max-width: 1400px;
  }
`

export const Text = styled(PageText)`
  color: ${props => (props.color ? props.color : "#ffffff")};
  font-size: ${props => (props.fontSize ? props.fontSize : ".9em")};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : fontWeights.normal};
`

export const Wrapper = styled(ItemWrapper)`
  display: flex;
  width: 100%;
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : "unset")};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "column"};
  align-items: ${props => (props.alignItems ? props.alignItems : "flex-start")};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "normal"};
  margin: ${props => (props.margin ? props.margin : "0")};
  padding: ${props => (props.padding ? props.padding : "0")};
`

export default GlobalStyles
