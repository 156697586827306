import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import { RightsRequest } from "../types/RightsRequest"
import { InstagramMediaObject } from "../types/Instagram"

// Define a type for the slice state
interface RightsRequestState {
  rightsRequest: RightsRequest | null
  instagramMedia: InstagramMediaObject | null
  selectedRightsRequests: string[]
  isLoadingRightsRequest: boolean
}

// Define the initial state using that type
const initialState: RightsRequestState = {
  rightsRequest: null,
  instagramMedia: null,
  selectedRightsRequests: [],
  isLoadingRightsRequest: true,
}

export const rightsRequestSlice = createSlice({
  name: "rightsRequest",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setRightsRequest: (state, action: PayloadAction<RightsRequest>) => {
      state.rightsRequest = action.payload
    },
    setIsLoadingRightsRequest: (state, action: PayloadAction<boolean>) => {
      state.isLoadingRightsRequest = action.payload
    },
    updateRightsRequest: (state, action: PayloadAction<RightsRequest>) => {
      state.rightsRequest = { ...state.rightsRequest, ...action.payload }
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setInstagramMedia: (state, action: PayloadAction<InstagramMediaObject>) => {
      state.instagramMedia = action.payload
    },
    addRightsRequest: (state, action: PayloadAction<string>) => {
      state.selectedRightsRequests = [
        ...state.selectedRightsRequests,
        action.payload,
      ]
    },
    removeRightsRequest: (state, action: PayloadAction<string>) => {
      state.selectedRightsRequests = state.selectedRightsRequests.filter(
        id => id !== action.payload
      )
    },
    selectAllRightsRequests: (state, action: PayloadAction<string[]>) => {
      state.selectedRightsRequests = action.payload
    },
    clearSelectedRightsRequest: state => {
      state.selectedRightsRequests = []
    },
  },
})

export const {
  setRightsRequest,
  setInstagramMedia,
  updateRightsRequest,
  addRightsRequest,
  removeRightsRequest,
  selectAllRightsRequests,
  clearSelectedRightsRequest,
  setIsLoadingRightsRequest,
} = rightsRequestSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectRightsRequest = (state: RootState) =>
  state.rightsRequest.rightsRequest
export const selectInstagramMedia = (state: RootState) =>
  state.rightsRequest.instagramMedia
export const selectSelectedRightsRequests = (state: RootState) =>
  state.rightsRequest.selectedRightsRequests
export const selectIsLoadingRightsRequests = (state: RootState) =>
  state.rightsRequest.isLoadingRightsRequest

export default rightsRequestSlice.reducer
