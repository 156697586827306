import * as S from "./text-styles"

interface Props {
  size: string
  children: React.ReactNode
}

const Text: React.FC<Props> = ({ size, children }) => {
  return <S.Text size={size}>{children}</S.Text>
}

export default Text
