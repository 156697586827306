import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import moment from "moment"
import {
  fetchRightsRequestStatus,
  handleRightsRequest,
} from "../../../../services/RightRequestService"
import { useAppSelector } from "../../../../redux/hooks"
import { selectRightsRequest } from "../../../../redux/rightsRequestSlice"
import { ASSET_RIGHT_STATUS } from "../../../../constants"
import ApproveRejectButton from "../../../../components/commons/ApproveRejectButton"
import Alert from "../../../../components/commons/Alert"
import { colors } from "../../../../styles/variables"
import { RightsRequestStatus } from "../../../../types/RightsRequest"
import Loader from "../../../../components/commons/loader"
import { Wrapper } from "../../../../styles/global"
import Text from "../../../../components/commons/Text"

const ApproveRejectRightsRequest: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isRejecting, setIsRejecting] = useState<boolean>(false)
  const [status, setStatus] = useState<RightsRequestStatus | undefined>()
  const rightsRequest = useAppSelector(selectRightsRequest)

  useEffect(() => {
    if (rightsRequest) {
      fetchRightsRequestStatus(rightsRequest.id)
        .then(res => {
          console.log(res)
          setStatus(res)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [rightsRequest])

  const handleApproval = async (approvalStatus: string) => {
    setIsSaving(true)
    if (rightsRequest) {
      try {
        const res = await handleRightsRequest(rightsRequest.id, approvalStatus)

        setStatus({
          linkStatus: res?.linkStatus,
          approvalStatus: res?.approvalStatus,
          completedAt: res?.completedAt,
        } as RightsRequestStatus)
        setIsSaving(false)
      } catch (err) {
        console.log(err)
        setIsSaving(false)
      }
    }
  }

  const handleApprove = async (approvalStatus: string) => {
    setIsApproving(true)
    await handleApproval(approvalStatus)
    setIsApproving(false)
  }

  const handleReject = async (approvalStatus: string) => {
    setIsRejecting(true)
    await handleApproval(approvalStatus)
    setIsRejecting(false)
  }

  if (isLoading) {
    return (
      <div style={{ height: "70px" }}>
        <Loader />
      </div>
    )
  }

  return (
    <Row className="justify-content-md-center pt-4">
      <Col>
        <div className="d-flex flex-column">
          {status?.linkStatus !== "COMPLETED" ? (
            isApproving || isRejecting ? (
              <span className="d-flex flex-row justify-content-center align-items-center mb-3">
                <Loader />
                <span style={{ marginLeft: "0.5rem" }}>Please wait...</span>
              </span>
            ) : (
              <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                <ApproveRejectButton
                  disabled={isSaving}
                  loading={isApproving}
                  color={colors.reelerLight}
                  onClick={() => handleApprove(ASSET_RIGHT_STATUS.approved)}
                >
                  <FaThumbsUp style={{ marginRight: "0.5rem" }} /> Approve
                </ApproveRejectButton>
                <ApproveRejectButton
                  disabled={isSaving}
                  loading={isRejecting}
                  color={colors.reelerDangerLight}
                  onClick={() => handleReject(ASSET_RIGHT_STATUS.notApproved)}
                >
                  <FaThumbsDown style={{ marginRight: "0.5rem" }} /> Reject
                </ApproveRejectButton>
              </div>
            )
          ) : (
            <Alert variant="success">
              <Wrapper>
                {status?.approvalStatus === ASSET_RIGHT_STATUS.approved ? (
                  <p className="lead">Thank you!</p>
                ) : null}
                <Wrapper flexDirection="row">
                  <strong style={{ marginRight: "0.5rem" }}>Status: </strong>

                  <Text size="1rem">
                    {`${
                      status?.approvalStatus === ASSET_RIGHT_STATUS.notApproved
                        ? "Not approved"
                        : status?.approvalStatus === ASSET_RIGHT_STATUS.approved
                        ? "Approved"
                        : status?.approvalStatus
                    } (${moment(status.completedAt).format("YYYY-MM-DD")})`}
                  </Text>
                </Wrapper>
              </Wrapper>
            </Alert>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default ApproveRejectRightsRequest
