import React from "react"

import { useAppSelector } from "../../../redux/hooks"
import parse from "html-react-parser"
import {
  selectIsLoadingRightsRequests,
  selectRightsRequest,
} from "../../../redux/rightsRequestSlice"
import {
  SkeletonImage,
  SkeletonContainer,
  SkeletonText,
} from "../../../components/commons/Skeleton"
import { Wrapper } from "../../../styles/global"

const RightsRequestTemplate: React.FC = () => {
  const rightsRequest = useAppSelector(selectRightsRequest)
  const isLoading = useAppSelector(selectIsLoadingRightsRequests)

  if (isLoading && !rightsRequest) {
    return (
      <Wrapper>
        <div className="d-flex align-self-center p-4">
          <SkeletonImage width="200px" height="150px" />
        </div>
        <SkeletonContainer>
          <div className="mb-3">
            <SkeletonText width="75%" />
          </div>
          <SkeletonText width="100%" />
          <SkeletonText width="100%" />
          <SkeletonText width="100%" />
          <SkeletonText width="100%" />
        </SkeletonContainer>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {rightsRequest?.approvalPage?.logo_url ? (
        <div className="d-flex align-self-center p-4">
          <img
            alt="Brand logo"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
              maxHeight: "80px",
            }}
            src={rightsRequest.approvalPage?.logo_url}
          />
        </div>
      ) : null}

      {/* <p style={{ fontSize: "1rem", whiteSpace: "pre-line" }}> */}
      {rightsRequest?.approvalPage?.rightsRequestText &&
        parse(rightsRequest?.approvalPage?.rightsRequestText)}
      {/* </p> */}
    </Wrapper>
  )
}

export default RightsRequestTemplate
