/**
 * Check if an img src is valid
 * @param url to image
 * @returns Promise
 */

export const checkImageURL = (url: string): Promise<string> =>
  new Promise(resolve => {
    const img = new Image()
    img.onload = () => resolve("ok")
    img.onerror = () => resolve("error")

    img.src = url
  })
