import styled from "styled-components"
import { colors } from "../../../styles/variables"

interface VideoProps {
  type?: string
}

export const MediaContainer = styled.div`
  width: 100%;
`
export const Image = styled.img`
  object-fit: contain;
  display: block;
  height: 100%;
  width: 100%;
`
export const Video = styled.video<VideoProps>`
  width: 100%;
  background: ${colors.white};
`
