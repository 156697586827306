import styled from "styled-components"

export const Styles = styled.section`
  padding-top: 50px;
  background-color: #fff;
`
export const MediaContainer = styled.div`
  height: 200px;

  text-align: center !important;
`
export const ContentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
`
