import React from "react"
import { Wrapper } from "../../../styles/global"
import logo from "../../../assets/Reeler-grey-transparent-90x21.png"

const Footer: React.FC = () => {
  return (
    <Wrapper
      flexDirection="row"
      justifyContent="end"
      alignItems="center"
      padding="2rem 0"
    >
      <div className="d-flex justify-content-center align-items-center">
        <small
          style={{
            color: "var(--reeler-grey-darker)",
            fontSize: "8px",
          }}
        >
          Powered by
        </small>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.reelertech.com"
        >
          <img
            src={logo}
            width="50px"
            style={{ paddingBottom: "2px", marginLeft: "0.25rem" }}
          />
        </a>
      </div>
    </Wrapper>
  )
}

export default Footer
