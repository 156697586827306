import React from "react"
import { Row, Col } from "react-bootstrap"
import { useAppSelector } from "../../../redux/hooks"

import ApproveRejectRightsRequest from "./ApproveRejectRightsRequest"
import Media from "../../../components/commons/Media"

import {
  selectIsLoadingRightsRequests,
  selectRightsRequest,
} from "../../../redux/rightsRequestSlice"
import Loader from "../../../components/commons/loader"
import RightsRequestChildren from "./RightsRequestChildren"
import { MEDIA_TYPE } from "../../../constants"

const LoadRightsRequestChildren: React.FC = () => {
  const rightsRequest = useAppSelector(selectRightsRequest)
  const isLoading = useAppSelector(selectIsLoadingRightsRequests)

  if (isLoading && !rightsRequest?.children) {
    return <Loader />
  }

  return rightsRequest?.media_type !== MEDIA_TYPE.TEXT &&
    rightsRequest?.children ? (
    <Row className="justify-content-md-center">
      <Col md={8}>
        <RightsRequestChildren />
      </Col>
    </Row>
  ) : rightsRequest?.media_type !== MEDIA_TYPE.TEXT && rightsRequest?.media ? (
    <Row className="justify-content-md-center pt-3">
      <Col md={6} className="d-flex flex-column">
        <ApproveRejectRightsRequest />
        {rightsRequest?.media?.media_url && rightsRequest?.media?.media_type ? (
          <Media
            url={rightsRequest?.media?.media_url}
            mediaType={rightsRequest?.media?.media_type}
          />
        ) : null}
      </Col>
    </Row>
  ) : (
    <Row className="justify-content-md-center mt-5">
      <Col md={6} className="d-flex flex-column">
        <div
          className="d-flex justify-content-center align-items-center p-5"
          style={{
            backgroundColor: "#fff3cd",
            border: "1px solid #ffd75e",
          }}
        >
          <strong>There are no content assets to review</strong>
        </div>
      </Col>
    </Row>
  )
}

export default LoadRightsRequestChildren
