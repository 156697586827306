import React from "react"
import { useAppSelector } from "../../../../redux/hooks"
import { selectRightsRequest } from "../../../../redux/rightsRequestSlice"

import { Row, Col } from "react-bootstrap"

const RightsRequestFormFieldValues: React.FC = () => {
  const rightsRequest = useAppSelector(selectRightsRequest)
  return rightsRequest && rightsRequest?.form_fields_values?.length > 0 ? (
    <>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column">
          {rightsRequest?.form_fields_values?.map(
            (
              value: {
                label: string
                database_name: string
                value: string
              },
              index: React.Key
            ) => (
              <div key={index}>
                <strong>
                  {value?.label
                    ? value.label
                    : value?.database_name
                    ? value?.database_name
                    : ""}
                </strong>
                <p>{value.value}</p>
              </div>
            )
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </>
  ) : null
}

export default RightsRequestFormFieldValues
