import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import { InstagramAuth } from "../types/RightsRequest"

// Define a type for the slice state
interface AuthState {
  instagram: InstagramAuth | null
}

// Define the initial state using that type
const initialState: AuthState = {
  instagram: null,
}

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setInstagramAuth: (state, action: PayloadAction<InstagramAuth>) => {
      state.instagram = action.payload
    },
  },
})

export const { setInstagramAuth } = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectInstagramAuth = (state: RootState) => state.auth.instagram

export default authSlice.reducer
