import styled from "styled-components"
import { colors } from "../../../styles/variables"

interface AlertProps {
  className?: string
  variant?: string
  children: React.ReactNode
}

export const Container = styled.div<AlertProps>`
  background: ${props =>
    props.variant === "success"
      ? colors.reelerSuperLight
      : props.variant === "danger"
      ? colors.reelerDangerLight
      : colors.reelerGreyMiddle};
  color: ${colors.black};
  padding: 0.25em;
  margin: 0.5rem 0;
`
