import React, { useEffect, useState } from "react"
import moment from "moment"
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks"
import {
  selectRightsRequest,
  updateRightsRequest,
  selectAllRightsRequests,
  clearSelectedRightsRequest,
  selectSelectedRightsRequests,
} from "../../../../redux/rightsRequestSlice"
// Third party

import { Row, Col } from "react-bootstrap"
import ApprovalAsset from "./components/ApprovalAsset"
import { RIGHT_REQUEST_STATUS } from "../../../../constants"
import ApproveRejectButton from "../../../../components/commons/ApproveRejectButton"
import { colors } from "../../../../styles/variables"
import {
  fetchRightsRequestStatus,
  handleApproveMultipleRightsRequests,
} from "../../../../services/RightRequestService"
import {
  RightsRequest,
  RightsRequestStatus,
} from "../../../../types/RightsRequest"
import DownloadMedia from "../DownloadMedia"
import Loader from "../../../../components/commons/loader"
import * as S from "./ThirdPartyApproval-styles"
//import DownloadAssets from "./components/DownloadAssets"

moment.locale()

const RightsRequestChildren: React.FC = () => {
  const dispatch = useAppDispatch()
  const rightsRequest = useAppSelector(selectRightsRequest)
  const approvedRightsRequests = useAppSelector(selectSelectedRightsRequests)
  const [status, setStatus] = useState<RightsRequestStatus | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  //const approvedAssets = useSelector(selectApprovedAssets)

  useEffect(() => {
    if (rightsRequest) {
      fetchRightsRequestStatus(rightsRequest.id)
        .then(res => {
          setStatus(res)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [rightsRequest])

  useEffect(() => {
    if (
      rightsRequest?.children &&
      rightsRequest?.linkStatus !== RIGHT_REQUEST_STATUS.completed
    ) {
      dispatch(
        selectAllRightsRequests(rightsRequest?.children?.map(child => child.id))
      )
    }
  }, [])

  const handleCheckAll = () => {
    if (rightsRequest?.children) {
      dispatch(
        selectAllRightsRequests(rightsRequest?.children?.map(child => child.id))
      )
    }
  }

  const handleUnheckAll = () => {
    dispatch(clearSelectedRightsRequest())
  }

  const handleSubmit = async () => {
    if (rightsRequest) {
      setIsSaving(true)
      try {
        const res = await handleApproveMultipleRightsRequests(
          rightsRequest.id,
          approvedRightsRequests
        )

        dispatch(updateRightsRequest({ ...res } as RightsRequest))
        setIsSaving(false)
      } catch (err) {
        console.log(err)
        setIsSaving(false)
      }
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    rightsRequest && (
      <>
        <Row className="my-3">
          <Col className="d-flex flex-row">
            <S.Link
              disabled={status?.linkStatus === RIGHT_REQUEST_STATUS.completed}
              onClick={handleCheckAll}
            >
              Select all
            </S.Link>
            <S.Link
              className="mx-3"
              disabled={status?.linkStatus === RIGHT_REQUEST_STATUS.completed}
              onClick={handleUnheckAll}
            >
              Deselect all
            </S.Link>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <S.ContentContainer>
              {rightsRequest?.children?.map(child => (
                <ApprovalAsset
                  key={child.id}
                  child={child}
                  parentStatus={status}
                />
              ))}
            </S.ContentContainer>
          </Col>
        </Row>
        {rightsRequest.children && (
          <Row className="mb-4">
            <Col>
              <DownloadMedia children={rightsRequest.children} />
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col>
            {rightsRequest?.linkStatus === RIGHT_REQUEST_STATUS.completed ? (
              <strong>
                {"[Response submitted: " +
                  moment(rightsRequest.completedAt).format("YYYY-MM-DD") +
                  "]"}
              </strong>
            ) : (
              <ApproveRejectButton
                onClick={() => handleSubmit()}
                color={
                  approvedRightsRequests?.length > 0
                    ? colors.reelerLight
                    : colors.reelerDangerLight
                }
                disabled={isSaving}
                loading={isSaving}
              >
                {approvedRightsRequests?.length > 0
                  ? "Approve Selected Content"
                  : "Submit with No Approvals"}
              </ApproveRejectButton>
            )}
            <div className="mt-3">
              <small>
                <i>
                  Only checked content is approved for use. Unchecked content is
                  not approved.
                </i>
              </small>
            </div>
          </Col>
        </Row>
      </>
    )
  )
}

export default RightsRequestChildren
