import React from "react"
import moment from "moment"

// Third party

import { Row, Col } from "react-bootstrap"

import { selectConfirmation } from "../../../redux/confirmationSlice"
import { useAppSelector } from "../../../redux/hooks"

//import DownloadAssets from "./components/DownloadAssets"

moment.locale()

const ConfirmationValues: React.FC = () => {
  const confirmation = useAppSelector(selectConfirmation)

  return confirmation && confirmation?.values ? (
    <>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <hr className="solid" />
          <h2>Submitted answers</h2>
          <Row>
            <Col className="d-flex flex-column">
              {confirmation?.order
                ? confirmation.order.map(fieldId => {
                    const field = confirmation?.values[fieldId]
                    if (field && field?.value) {
                      return (
                        <Row key={fieldId}>
                          <Col>
                            <strong>
                              {field?.label
                                ? field.label
                                : field?.database_name
                                ? field?.database_name
                                : ""}
                            </strong>
                            <p>{field.value}</p>
                          </Col>
                        </Row>
                      )
                    }
                    return null
                  })
                : Object.keys(confirmation?.values).map(fieldId => {
                    const field = confirmation?.values[fieldId]
                    if (field && field?.value) {
                      return (
                        <Row key={fieldId}>
                          <Col>
                            <strong>
                              {field?.label
                                ? field.label
                                : field?.database_name
                                ? field?.database_name
                                : ""}
                            </strong>
                            <p>{field.value}</p>
                          </Col>
                        </Row>
                      )
                    }
                    return null
                  })}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : null
}

export default ConfirmationValues
