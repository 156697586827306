import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { useAppDispatch } from "../../redux/hooks"
import { fetchRightsRequest } from "../../services/RightRequestService"
import { RightsRequest } from "../../types/RightsRequest"
import {
  setRightsRequest,
  setIsLoadingRightsRequest,
} from "../../redux/rightsRequestSlice"
import * as S from "./RightRequestPage.styles"

import Footer from "../../components/commons/Footer"
import RightsRequestTemplate from "./components/RightsRequestTemplate"
import TermsAndConditions from "./components/TermsAndConditions"

import LoadRightsRequestChildren from "./components/LoadRightsRequestChildren"
import RightsRequestFormFieldValues from "./components/RightsRequestFormFieldValues"

const RightsRequestPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const { rightRequestShortId } = useParams()

  useEffect(() => {
    if (rightRequestShortId) {
      fetchRightsRequest(rightRequestShortId)
        .then(res => {
          dispatch(setRightsRequest({ ...res } as RightsRequest))
          dispatch(setIsLoadingRightsRequest(false))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [rightRequestShortId])

  return (
    <S.Styles>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <RightsRequestTemplate />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={8} className="d-flex flex-column">
            <TermsAndConditions />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <RightsRequestFormFieldValues />
          </Col>
        </Row>

        <LoadRightsRequestChildren />

        <Footer />
      </Container>
    </S.Styles>
  )
}

export default RightsRequestPage
