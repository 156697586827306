import { initializeApp } from "firebase/app"
import { getAuth, Auth } from "firebase/auth"
import {
  Firestore,
  increment,
  serverTimestamp,
  collection,
  getFirestore,
} from "firebase/firestore"
import { Functions, getFunctions } from "firebase/functions"

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

export const getCurrentUser = () => {
  /**
   * When we return this promise the promise will resolve to the correct user value if there is one,
   * else it will return null
   * Reject will catch any errors inside the API listener
   */
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const auth: Auth = getAuth(app)
export const db: Firestore = getFirestore(app)
export const functions: Functions = getFunctions(app)

export const database = {
  campaigns: collection(db, "campaigns"),

  adminCollection: "reeler_admins",

  formatDoc: (doc: { id: string; data: () => any }) => {
    return { id: doc.id, ...doc.data() }
  },

  increment: increment(1),
  decrement: increment(-1),
  getCurrentTimestamp: serverTimestamp,
  getCurrentUser,
}

export default app
