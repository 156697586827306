import * as S from "./skeleton-styles"

interface Props {
  width: "25%" | "50%" | "75%" | "100%"
}

const SkeletonContainer: React.FC<Props> = ({ width }) => {
  return <S.SkeletonText width={width} />
}

export default SkeletonContainer
