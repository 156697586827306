import React from "react"

interface Props {
  className?: string
  display?: string
  flexDirection?: string
  flexWrap?: string
  alignItems?: string
  justifyContent?: string
  maxWidth?: string
  margin?: string
  padding?: string
  children: React.ReactNode
}
export const PageItemWrapper: React.FC<Props> = ({ className, children }) => {
  return <div className={className}>{children}</div>
}
