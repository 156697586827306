import React from "react"

import { useAppSelector } from "../../../redux/hooks"
import parse from "html-react-parser"

import { Wrapper } from "../../../styles/global"
import { selectConfirmation } from "../../../redux/confirmationSlice"

const ConfirmationPageTemplate: React.FC = () => {
  const confirmation = useAppSelector(selectConfirmation)

  return (
    <Wrapper>
      {confirmation?.image_url ? (
        <div className="d-flex align-self-center p-4 mb-3">
          <img
            alt="Brand logo"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
              maxHeight: "200px",
            }}
            src={confirmation?.image_url}
          />
        </div>
      ) : null}

      {/* <p style={{ fontSize: "1rem", whiteSpace: "pre-line" }}> */}
      {confirmation?.intro && parse(confirmation?.intro)}
      {/* </p> */}
    </Wrapper>
  )
}

export default ConfirmationPageTemplate
