import * as S from "./skeleton-styles"

interface Props {
  children: React.ReactNode
}

const SkeletonContainer: React.FC<Props> = ({ children }) => {
  return <S.SkeletonContainer>{children}</S.SkeletonContainer>
}

export default SkeletonContainer
