import styled from "styled-components"
import { colors } from "../../../styles/variables"

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void // to handle onClick functions
  children: React.ReactNode
  color: string
}

export const Button = styled.button<ButtonProps>`
  padding: 15px 30px;
  border: none;
  display: flex;
  align-items: center;
  background-color: ${props => props.color};
  color: ${colors.black};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background: ${props => props.color + "b3"};
    transition: all 0.3s;
  }
`
