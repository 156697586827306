import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { fetchForm } from "../../../services/FormService"
import { Form } from "../../../types/Form"

const FormPage: React.FC = () => {
  const { campaignId } = useParams()
  const [form, setForm] = useState<Form>()

  useEffect(() => {
    if (campaignId) {
      fetchForm(campaignId).then(res => {
        console.log(res)
        setForm(res)
      })
    }
  }, [campaignId])

  useEffect(() => {
    if (form) {
      const script = document.createElement("script")

      script.src =
        process.env.REACT_APP_FIREBASE_WIDGET_URL + "/ReelerWidgets.js"
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    } else {
      return
    }
  }, [form])

  return (
    <Container
      fluid
      className="ugc_landingpage_container 100-hv"
      style={{
        background:
          form && form.formSchema && form.formSchema?.design?.formBackground
            ? form.formSchema?.design?.formBackground
            : "#fff",
      }}
    >
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <div id="reeler-widget" data-campaign={campaignId}></div>
        </Col>
      </Row>
    </Container>
  )
}

export default FormPage
