import React, { useState, useEffect } from "react"

import * as S from "../ThirdPartyApproval-styles"
import { Modal, Button } from "react-bootstrap"
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks"
import {
  selectSelectedRightsRequests,
  addRightsRequest,
  removeRightsRequest,
} from "../../../../../redux/rightsRequestSlice"
import {
  RightsRequestChild,
  RightsRequestStatus,
} from "../../../../../types/RightsRequest"
import {
  RIGHTS_REQUEST_APPROVAL_STATUS,
  RIGHT_REQUEST_STATUS,
} from "../../../../../constants"
import { fetchRightsRequestStatus } from "../../../../../services/RightRequestService"
import { SkeletonImage } from "../../../../../components/commons/Skeleton"
import Media from "../../../../../components/commons/Media"
// import {
//   addAsset,
//   removeAsset,
// } from "../../../../redux/third-party-approval/third-party-approval.actions"
//import { selectApprovedAssets } from "../../../../redux/third-party-approval/third-party-approval.selectors"

interface ApprovalAssetProps {
  child: RightsRequestChild
  parentStatus: RightsRequestStatus | undefined
}

const ApprovalAsset: React.FC<ApprovalAssetProps> = ({
  child,
  parentStatus,
}) => {
  const dispatch = useAppDispatch()
  const [show, setShow] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const approvedRightsRequests = useAppSelector(selectSelectedRightsRequests)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (parentStatus?.linkStatus === RIGHT_REQUEST_STATUS.completed) {
      setIsLoading(true)
      fetchRightsRequestStatus(child.id)
        .then(res => {
          const childStatus = res

          if (
            childStatus?.approvalStatus ===
            RIGHTS_REQUEST_APPROVAL_STATUS.approved
          ) {
            setIsChecked(true)
          } else {
            setIsChecked(false)
          }
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      if (approvedRightsRequests) {
        const assetIsApproved = approvedRightsRequests.filter(
          id => id === child.id
        )
        if (assetIsApproved.length > 0) {
          setIsChecked(true)
        } else {
          setIsChecked(false)
        }
      }
    }
  }, [approvedRightsRequests])

  const handleCheck = () => {
    if (isChecked) {
      dispatch(removeRightsRequest(child.id))
    } else {
      dispatch(addRightsRequest(child.id))
    }
  }

  if (isLoading || !parentStatus) {
    return (
      <S.AssetContainer isChecked={false}>
        <SkeletonImage height="200px" width="100%" />
      </S.AssetContainer>
    )
  }

  return (
    <>
      <S.AssetContainer isChecked={isChecked}>
        <S.TopLeft>
          <div style={{ marginLeft: "0.25rem" }}>
            <input
              type="checkbox"
              checked={isChecked}
              onClick={handleCheck}
              disabled={
                parentStatus.linkStatus === RIGHT_REQUEST_STATUS.completed
              }
            />
          </div>
        </S.TopLeft>

        <S.MediaContainer onClick={() => handleShow()}>
          {child.media && (
            <Media
              url={child.media?.media_url}
              mediaType={child.media?.media_type}
            />
          )}
        </S.MediaContainer>
      </S.AssetContainer>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {child.media && (
            <Media
              url={
                process.env.REACT_APP_FIREBASE_API_URL +
                "/v1.0/media/" +
                child.media?.id
              }
              mediaType={child.media?.media_type}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {/* <DownloadFile asset={asset} /> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ApprovalAsset
