import React, { useState } from "react"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import Loader from "../../../../components/commons/loader"
import { RightsRequestChild } from "../../../../types/RightsRequest"

const zip = new JSZip()

interface Props {
  zipname?: string
  children: RightsRequestChild[]
}

const DownloadMedia: React.FC<Props> = ({
  zipname = "reeler.zip",
  children,
}) => {
  const [isZiping, setIsZipping] = useState<boolean>(false)

  const handleZipMediaObjects = () => {
    setIsZipping(true)

    const remoteZips = children.map(async (child, index) => {
      if (child?.media?.id === undefined) {
        return null
      }
      const url =
        process.env.REACT_APP_FIREBASE_API_URL +
        "/v1.0/media/" +
        child.media?.id
      const fileType = child.media.file_type
      const fileExtension = fileType.split("/")[1]
      const response = await fetch(url)
      const data = await response.blob()

      const filename = "File_" + (index + 1) + "." + fileExtension

      zip.file(filename, data)

      return data
    })

    Promise.all(remoteZips).then(() => {
      zip.generateAsync({ type: "blob" }).then(content => {
        saveAs(content, zipname)
        setIsZipping(false)
      })
    })
  }

  return (
    <div
      onClick={() => handleZipMediaObjects()}
      style={{ textDecoration: "underline", cursor: "pointer" }}
    >
      {isZiping ? (
        <span className="d-flex flex-row">
          <Loader />{" "}
          <span style={{ marginLeft: "0.5rem" }}>
            Please wait while downloading
          </span>
        </span>
      ) : (
        "Download all files"
      )}
    </div>
  )
}

export default DownloadMedia
