import React, { useEffect, useState } from "react"
import { SkeletonImage } from "../Skeleton"

import * as S from "./ContentMedia.styles"
import { MEDIA_TYPE } from "../../../constants"
import { checkImageURL } from "../../../utils/checkImageURL"

interface Props {
  url: string
  mediaType: string
}

const Media: React.FC<Props> = ({ url, mediaType }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (mediaType === MEDIA_TYPE.IMAGE) {
      checkImageURL(url)
        .then(result => {
          if (result === "ok") {
            setIsLoading(false)
          }
        })
        .catch(err => console.log(err))
    } else {
      setIsLoading(false)
    }
  })

  const renderMediaObject = () => {
    switch (mediaType) {
      case MEDIA_TYPE.IMAGE:
        return <S.Image src={url} />
      case MEDIA_TYPE.VIDEO:
        return (
          <S.Video
            height="100%"
            width="100%"
            type="video/mp4"
            controls
            src={url}
          />
        )
      default:
        return null
    }
  }

  if (isLoading) {
    return <SkeletonImage height="200px" width="100%" />
  }

  return renderMediaObject()
}

export default Media
